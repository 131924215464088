<template>
    <div>
        <div class="row">
            <div class="col-md-3">
                <div class="info-box">
                    <span class="info-box-icon bg-primary"><i class="'text-white fas fa-list"></i></span>
                    <div class="info-box-content">
                        <span class="info-box-text">تعداد کل سفارشات</span>
                        <button  class="info-box-number">{{ dashboard ? dashboard.total_provides : 0}}</button>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="info-box">
                    <span class="info-box-icon bg-warning"><i class="'text-white fas fa-shopping-cart"></i></span>
                    <div class="info-box-content">
                        <span class="info-box-text">تعداد کل محصولات</span>
                        <button  class="info-box-number">{{ dashboard ? dashboard.total_products : 0}}</button>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="info-box">
                    <span class="info-box-icon bg-success"><i class="'text-white fas fa-cube"></i></span>
                    <div class="info-box-content">
                        <span class="info-box-text">پرداختی (تومان)</span>
                        <button v-if="dashboard.paid_amount != 0" class="info-box-number">{{ dashboard.paid_amount | price}}</button>
                        <button v-else class="info-box-number">0</button>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="info-box">
                    <span class="info-box-icon bg-danger"><i class="'text-white fas fa-cube"></i></span>
                    <div class="info-box-content">
                        <span class="info-box-text">پرداختی در انتظار (تومان)</span>
                        <button  class="info-box-number">{{ dashboard.to_pay_amount | price}}</button>
                    </div>
                </div>
            </div>
        </div>
        <b-card>
            <router-link to="/panel/provides">
                <h5 class="fontWeightBold mb-4">سفارشات جدید</h5>
            </router-link>
            <b-table responsive striped outlined hover :fields="order_table_fields" :items="dashboard.init_provides">
                <template v-slot:cell(status)="data">
                    <div style="width: 200px;">
                        <p v-if="data.item.status == 'init' " class="p-1 bg-secondary text-center small rounded">
                            ثبت شده</p>
                        <!-- <p v-else-if="data.item.status == '1'" class="p-1 bg-warning text-center small rounded">
                            در حال جمع آوری
                        </p> -->
                        <p v-else-if="data.item.status == 'sending' " class="p-1 bg-info text-center small rounded">
                            در حال ارسال</p>
                        <p v-else-if="data.item.status == 'unavailable' "
                            class="p-1 bg-primary text-center small rounded">
                            موجود نیست
                        </p>
                        <p v-else-if="data.item.status == 'sent' " class="p-1 bg-success text-center small rounded">
                            ارسال شد
                        </p>
                    </div>
                </template>
                <template v-slot:cell(invoice_number)="data">
                    <button class="cursor-pointer" @click="showOrderDetails(data.index)">
                        {{ data.item.invoice.invoice_number }}
                    </button>

                </template>

                <template v-slot:cell(created_at)="data">
                    <p v-if="data.item.created_at">{{ data.item.created_at | persianDate}}</p>
                </template>
                <template v-slot:cell(index)="data">
                    {{ data.index + 1  }}
                </template>

                <template v-slot:cell(details)="data">
                    <button class="btn-sm btn btn-info" @click="showOrderDetails(data.index)">
                        <i class="fa fa-info"></i>
                    </button>
                </template>
            </b-table>
            <div class="alert alert-danger text-right" v-if="dashboard.init_provides == ''">
                <span class="text-right">موردی یافت نشد ! </span>
            </div>
        </b-card>
        <b-card>
            <router-link to="/panel/tickets">
                <h5 class="fontWeightBold mb-4">تیکت ها</h5>
            </router-link>
            <b-table responsive striped outlined hover :fields="ticket_table_fields" :items="dashboard.pending_tickets">
                <template v-slot:cell(id)="data">
                    <router-link :to="{ name: 'ticket.show', params: { id: data.item.id } }">
                        {{data.item.id}}
                    </router-link>
                </template>
                <template v-slot:cell(title)="data">
                    <router-link :to="{ name: 'ticket.show', params: { id: data.item.id } }">
                        {{data.item.title}}
                    </router-link>
                </template>
                <template v-slot:cell(updated_at)="data">
                    <router-link :to="{ name: 'ticket.show', params: { id: data.item.id } }">
                        {{ data.item.updated_at | persianDate }}
                    </router-link>
                </template>
                <template v-slot:cell(status)="data">
                    <router-link :to="{ name: 'ticket.show', params: { id: data.item.id } }">
                        <p v-if="data.item.status == 'closed'" class="badge badge-danger p-2">
                            بسته شده
                        </p>
                        <p v-else-if="data.item.status == 'pending'" class="badge badge-warning p-2 text-light">
                            در حال انتظار
                        </p>
                        <p v-else-if="data.item.status == 'answered'" class="badge badge-success p-2">
                            پاسخ داده شده
                        </p>
                        <p v-else-if="data.item.status == 'submitted'" class="badge badge-primary p-2">
                            ثبت شده
                        </p>
                    </router-link>
                </template>
                <template v-slot:cell(show)="data">
                    <router-link :to="{ name: 'ticket.show', params: { id: data.item.id } }" class="btn btn-info">
                        <i class="fa fa-eye" aria-hidden="true" style="font-size: 18px;"></i>
                    </router-link>
                </template>
            </b-table>
            <div class="alert alert-danger text-right" v-if="dashboard.pending_tickets == ''">
                <span class="text-right">موردی یافت نشد ! </span>
            </div>
        </b-card>
        <b-modal id="order-detail" :title="' شماره فاکتور' + ' ' + orderDetail.invoice_number" hide-footer size="lg">
            <form @submit.prevent="orderUpdate" id="order-update">
                <div v-if="orderDetail.orders" class="box-shadow mt-3 rounded pt-1">
                    <b-table responsive striped hover class="mb-0" :fields="order_detail_table_fields"
                        :items="orderDetail.orders">
                        <template v-slot:cell(quantity_send)="data">
                            <div class="d-flex justify-content-start align-items-center">
                                <span class=" cursor-pointer increment p-0 text-center"
                                    @click=" orderDetail.status == 'unavailable' || orderDetail.status == 'sent' ? '' : increment(data.index)">
                                    <i class="fas fa-plus"></i>
                                </span>
                                <span class="border rounded " style="height: 30px; line-height: 5px;">
                                    {{data.item.pivot ? data.item.pivot.available_count : 0}}
                                </span>
                                <span class=" cursor-pointer increment p-0 text-center"
                                    @click=" orderDetail.status == 'unavailable' || orderDetail.status == 'sent' ? '' : decrement(data.index)">
                                    <i class="fas fa-minus"></i>
                                </span>
                            </div>
                        </template>
                        <template v-slot:cell(quantity)="data">
                            {{data.item.pivot ? data.item.pivot.wanted_count : 0}}
                        </template>
                        <template v-slot:cell(title)="data">
                            {{ data.item.variety ? data.item.variety.fullTitle : ''  }}
                        </template>
                        <template v-slot:cell(index)="data">
                            {{ data.index + 1  }}
                        </template>
                        <template v-slot:cell(description)="data">
                            <b-form-textarea v-model="data.item.description" rows="2"></b-form-textarea>
                        </template>
                    </b-table>
                </div>
                <div class="d-flex justify-content-between align-items-center mt-5 ">
                    <div class="d-flex justify-content-center">
                        <span class="fontWeightBold mt-2 ml-1">
                            وضعیت کلی فاکتور :
                        </span>
                        <b-form-select v-model="orderDetail.status" name="status"
                            :disabled="orderDetail.status == 'unavailable' || orderDetail.status == 'sent' ? true : false"
                            style="width: 200px;">
                            <b-form-select-option value="init" disabled>ثبت شده</b-form-select-option>
                            <b-form-select-option value="sending">در حال ارسال</b-form-select-option>
                            <!-- <b-form-select-option value="unavailable" disabled>موجود نیست</b-form-select-option> -->
                            <b-form-select-option value="sent" disabled>ارسال شد</b-form-select-option>
                        </b-form-select>
                    </div>
                    <div>
                        <input type="hidden" name="_method" value="PUT">
                        <button class="btn btn-success" type="submit"
                            :disabled="orderDetail.status == 'unavailable' || orderDetail.status == 'sent' || disabled ? true : false">
                            ثبت</button>
                    </div>

                </div>

            </form>

        </b-modal>
    </div>
</template>
<script>
    import mixins from './mixins/mixins'
    export default {
        mixins: [mixins],
        data() {
            return {
                title: 'صفحه اصلی',
                url: '/api/seller/dashboard',
                order_table_fields: [{
                        key: 'index',
                        label: 'ردیف'
                    },
                    {
                        key: 'invoice_number',
                        label: 'شماره فاکتور'
                    },
                    {
                        key: 'created_at',
                        label: 'تاریخ سفارش'
                    },
                    {
                        key: 'status',
                        label: 'وضعیت'
                    },
                    {
                        key: 'details',
                        label: 'اقلام'
                    }
                ],
                order_detail_table_fields: [{
                        key: 'title',
                        label: 'عنوان'
                    },
                    {
                        key: 'quantity',
                        label: 'تعداد سفارش'
                    },
                    {
                        key: 'quantity_send',
                        label: 'تعداد ارسال'
                    },
                ],
                ticket_table_fields: [{
                        key: 'id',
                        label: 'شناسه'
                    },
                    {
                        key: 'title',
                        label: 'عنوان'
                    },
                    {
                        key: 'updated_at',
                        label: 'آخرین به روزرسانی'
                    },
                    {
                        key: 'status',
                        label: 'وضعیت'
                    },
                    {
                        key: 'show',
                        label: 'مشاهده'
                    },
                ],
                orderDetail: {},
                btnDisabled: false,
                dashboard: {},
                // init_provides: [],
                // pending_tickets: []
            }
        },
        created() {
            this.loadDashboard()
        },
        methods: {
            loadDashboard() {
                this.$axios.get(this.url)
                    .then(response => {
                        this.dashboard = response.data.data
                        // this.pending_tickets = response.data.data.pending_tickets
                    })
            },
            showOrderDetails(index) {
                let item = window.clone(this.dashboard.init_provides[index])
                item.index = index
                this.orderDetail = item
                this.orderDetail.invoice_number = this.orderDetail.invoice.invoice_number
                this.$root.$emit('bv::show::modal', 'order-detail')
            },
            increment(index) {
                if (this.orderDetail.orders[index].pivot.available_count < this.orderDetail.orders[index].pivot
                    .wanted_count) {
                    this.orderDetail.orders[index].pivot.available_count = this.orderDetail.orders[index].pivot
                        .available_count + 1
                }
            },
            decrement(index) {
                if (this.orderDetail.orders[index].pivot.available_count > 0) {
                    this.orderDetail.orders[index].pivot.available_count = this.orderDetail.orders[index].pivot
                        .available_count - 1
                }
            },
            orderUpdate() {
                this.disabled = true
                let form = document.getElementById('order-update')
                let formData = new FormData(form)
                this.orderDetail.orders.map((el, index) => {
                    formData.append('orders[' + index + `][id]`, el.id)
                    formData.append('orders[' + index + `][available_count]`, el.pivot.available_count)
                })
                this.$axios.post(this.$root.baseUrl +
                        `/api/seller/provides/${this.orderDetail.id}`, formData)
                    .then((response) => {
                        this.$root.success_notification(response.data.message)
                        this.dashboard.init_provides[this.orderDetail.index].status = this.orderDetail.status
                        this.dashboard.init_provides[this.orderDetail.index].orders = response.data.data.orders
                    })
                    .catch((error) => {
                        this.$root.error_notification(error)
                    })
                    .finally(() => {
                        this.disabled = false
                    })
            },
        }
    }
</script>
<style scoped>
    .box-shadow {
        box-shadow: 5px 9px 25px 6px #0a0a0a21;
        overflow: hidden;
    }

    .cursor-pointer {
        cursor: pointer !important;
    }

    .increment {
        width: 30px;
        height: 30px;
        line-height: 30px;
    }

    .increment .fas {
        font-size: 11px;
    }
</style>
